import instagramVWCover from 'assets/img/projects/instagram/vw/instagram-vw-cover.png';
import instagramVWItem1 from 'assets/img/projects/instagram/vw/instagram-vw-item-1.png';
import instagramVWItem2 from 'assets/img/projects/instagram/vw/instagram-vw-item-2.png';
import instagramVWItem3 from 'assets/img/projects/instagram/vw/instagram-vw-item-3.png';
import instagramVWItem4 from 'assets/img/projects/instagram/vw/instagram-vw-item-4.png';
import instagramVWItem5 from 'assets/img/projects/instagram/vw/instagram-vw-item-5.png';
import instagramVWItem6 from 'assets/img/projects/instagram/vw/instagram-vw-item-6.png';
import instagramVWItem7 from 'assets/img/projects/instagram/vw/instagram-vw-item-7.png';
import instagramVWItem8 from 'assets/img/projects/instagram/vw/instagram-vw-item-8.png';
import instagramVWItem9 from 'assets/img/projects/instagram/vw/instagram-vw-item-9.png';
import instagramVWItem10 from 'assets/img/projects/instagram/vw/instagram-vw-item-10.png';
import instagramVWItem11 from 'assets/img/projects/instagram/vw/instagram-vw-item-11.png';

export const instagramVWContent = {
    name: 'Volkswagen',
    link: '/category/instagram/vw',
    description: 'Всемирно известный немецкий автомобильный бренд',
    projectUrl: 'https://www.instagram.com/volkswagen_belarus/',
    cover: instagramVWCover,
    bello: true,
    column1: [
        {
            url: instagramVWItem3,
            type: 'img'
        },
        {
            url: instagramVWItem7,
            type: 'img'
        },
    ],
    column2: [
        {
            url: instagramVWItem1,
            type: 'img'
        },
        {
            url: instagramVWItem10,
            type: 'img'
        },
        {
            url: instagramVWItem11,
            type: 'img'
        },
    ],
    column3: [
        {
            url: instagramVWItem6,
            type: 'img'
        },
        {
            url: instagramVWItem2,
            type: 'img'
        },
        {
            url: instagramVWItem8,
            type: 'img'
        },
    ],
    column4: [
        {
            url: instagramVWItem4,
            type: 'img'
        },
        {
            url: instagramVWItem9,
            type: 'img'
        },
        {
            url: instagramVWItem5,
            type: 'img'
        },
    ]
};
