export const NAVBAR_LINKS = [
    {
        name: 'Обо мне',
        link: 'about-section'
    },
    {
        name: 'Портфолио',
        link: 'portfolio-section'
    },
    {
        name: 'Бренды',
        link: 'brands-section'
    },
    {
        name: 'Цены',
        link: 'prices-section'
    },
    {
        name: 'Контакты',
        link: 'contacts-section'
    },
];
