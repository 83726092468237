import instagramLSSCover from 'assets/img/projects/instagram/lss/instagram-lss-cover.png';
import instagramLSSItem1 from 'assets/img/projects/instagram/lss/instagram-lss-item-1.png';
import instagramLSSItem2 from 'assets/img/projects/instagram/lss/instagram-lss-item-2.png';
import instagramLSSItem3 from 'assets/img/projects/instagram/lss/instagram-lss-item-3.png';
import instagramLSSItem4 from 'assets/img/projects/instagram/lss/instagram-lss-item-4.png';
import instagramLSSItem5 from 'assets/img/projects/instagram/lss/instagram-lss-item-5.png';
import instagramLSSItem6 from 'assets/img/projects/instagram/lss/instagram-lss-item-6.png';
import instagramLSSItem7 from 'assets/img/projects/instagram/lss/instagram-lss-item-7.png';
import instagramLSSItem8 from 'assets/img/projects/instagram/lss/instagram-lss-item-8.png';
import instagramLSSItem9 from 'assets/img/projects/instagram/lss/instagram-lss-item-9.png';
import instagramLSSItem10 from 'assets/img/projects/instagram/lss/instagram-lss-item-10.png';
import instagramLSSItem11 from 'assets/img/projects/instagram/lss/instagram-lss-item-11.png';

export const instagramLSSContent = {
    name: 'Little Sister Store',
    link: '/category/instagram/lss',
    description: 'Место, в котором каждый найдет вдохновение, красоту и уют',
    projectUrl: 'https://www.instagram.com/little.sister.store/',
    cover: instagramLSSCover,
    column1: [
        {
            url: instagramLSSItem1,
            type: 'img'
        },
        {
            url: instagramLSSItem2,
            type: 'img'
        },
    ],
    column2: [
        {
            url: instagramLSSItem3,
            type: 'img'
        },
        {
            url: instagramLSSItem4,
            type: 'img'
        },
        {
            url: instagramLSSItem5,
            type: 'img'
        },
    ],
    column3: [
        {
            url: instagramLSSItem6,
            type: 'img'
        },
        {
            url: instagramLSSItem7,
            type: 'img'
        },

        {
            url: instagramLSSItem8,
            type: 'img'
        },
    ],
    column4: [
        {
            url: instagramLSSItem9,
            type: 'img'
        },
        {
            url: instagramLSSItem10,
            type: 'img'
        },
        {
            url: instagramLSSItem11,
            type: 'img'
        },
    ]
};
