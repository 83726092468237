import instagramZiajaCover from 'assets/img/projects/instagram/ziaja/instagram-ziaja-cover.png';
import instagramZiajaItem1 from 'assets/img/projects/instagram/ziaja/instagram-ziaja-item-1.png';
import instagramZiajaItem2 from 'assets/img/projects/instagram/ziaja/instagram-ziaja-item-2.png';
import instagramZiajaItem3 from 'assets/img/projects/instagram/ziaja/instagram-ziaja-item-3.png';
import instagramZiajaItem4 from 'assets/img/projects/instagram/ziaja/instagram-ziaja-item-4.png';
import instagramZiajaItem5 from 'assets/img/projects/instagram/ziaja/instagram-ziaja-item-5.png';
import instagramZiajaItem6 from 'assets/img/projects/instagram/ziaja/instagram-ziaja-item-6.png';
import instagramZiajaItem7 from 'assets/img/projects/instagram/ziaja/instagram-ziaja-item-7.png';

export const instagramZiajaContent = {
    name: 'Ziaja',
    link: '/category/instagram/ziaja',
    description: 'Бренд экологичной косметики из Польши',
    date: 'с 2020 по январь 2022',
    cover: instagramZiajaCover,
    ginger: true,
    column1: [
        {
            url: instagramZiajaItem7,
            type: 'img'
        },
    ],
    column2: [
        {
            url: instagramZiajaItem2,
            type: 'img'
        },
        {
            url: instagramZiajaItem5,
            type: 'img'
        },
    ],
    column3: [
        {
            url: instagramZiajaItem6,
            type: 'img'
        },
        {
            url: instagramZiajaItem3,
            type: 'img'
        },
    ],
    column4: [
        {
            url: instagramZiajaItem4,
            type: 'img'
        },
        {
            url: instagramZiajaItem1,
            type: 'img'
        },
    ]
};
