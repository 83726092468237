import illustrationAllCover from 'assets/img/projects/illustration/all/illustration-all-cover.png';
import illustrationAllItem1 from 'assets/img/projects/illustration/all/illustration-all-item-1.png';
import illustrationAllItem2 from 'assets/img/projects/illustration/all/illustration-all-item-2.png';
import illustrationAllItem3 from 'assets/img/projects/illustration/all/illustration-all-item-3.png';
import illustrationAllItem4 from 'assets/img/projects/illustration/all/illustration-all-item-4.png';
import illustrationAllItem5 from 'assets/img/projects/illustration/all/illustration-all-item-5.jpg';
import illustrationAllItem6 from 'assets/img/projects/illustration/all/illustration-all-item-6.jpg';
import illustrationAllItem7 from 'assets/img/projects/illustration/all/illustration-all-item-7.png';
import illustrationAllItem8 from 'assets/img/projects/illustration/all/illustration-all-item-8.png';
import illustrationAllItem9 from 'assets/img/projects/illustration/all/illustration-all-item-9.png';
import illustrationAllItem10 from 'assets/img/projects/illustration/all/illustration-all-item-10.png';
import illustrationAllItem11 from 'assets/img/projects/illustration/all/illustration-all-item-11.MOV';
import illustrationAllItem12 from 'assets/img/projects/illustration/all/illustration-all-item-12.jpeg';
import illustrationAllItem13 from 'assets/img/projects/illustration/all/illustration-all-item-13.jpeg';
import illustrationAllItem14 from 'assets/img/projects/illustration/all/illustration-all-item-14.jpeg';
import illustrationAllItem15 from 'assets/img/projects/illustration/all/illustration-all-item-15.jpeg';
import illustrationAllItem16 from 'assets/img/projects/illustration/all/illustration-all-item-16.jpeg';
import illustrationAllItem19 from 'assets/img/projects/illustration/all/illustration-all-item-19.jpg';
import illustrationAllItem20 from 'assets/img/projects/illustration/all/illustration-all-item-20.jpg';
import illustrationAllItem21 from 'assets/img/projects/illustration/all/illustration-all-item-21.jpg';
import illustrationAllItem22 from 'assets/img/projects/illustration/all/illustration-all-item-22.png';
import illustrationAllItem23 from 'assets/img/projects/illustration/all/illustration-all-item-23.png';
import illustrationAllItem24 from 'assets/img/projects/illustration/all/illustration-all-item-24.jpg';
import illustrationAllItem25 from 'assets/img/projects/illustration/all/illustration-all-item-25.png';
import illustrationAllItem26 from 'assets/img/projects/illustration/all/illustration-all-item-26.png';
import illustrationAllItem27 from 'assets/img/projects/illustration/all/illustration-all-item-27.png';
import illustrationAllItem28 from 'assets/img/projects/illustration/all/illustration-all-item-28.png';

export const illustrationAllContent = {
    name: 'Иллюстрации',
    link: '/category/illustration/all',
    description: 'Часть того, что я нарисовала за несколько лет',
    date: 'с 2020 по настоящее время',
    cover: illustrationAllCover,
    column1: [
        {
            url: illustrationAllItem3,
            type: 'img'
        },
        {
            url: illustrationAllItem15,
            type: 'img'
        },
        {
            url: illustrationAllItem2,
            type: 'img'
        },
        {
            url: illustrationAllItem12,
            type: 'img'
        },
        {
            url: illustrationAllItem27,
            type: 'img'
        },
        {
            url: illustrationAllItem10,
            type: 'img'
        },
    ],
    column2: [
        {
            url: illustrationAllItem24,
            type: 'img'
        },
        {
            url: illustrationAllItem8,
            type: 'img'
        },
        {
            url: illustrationAllItem19,
            type: 'img'
        },
        {
            url: illustrationAllItem26,
            type: 'img'
        },
        {
            url: illustrationAllItem9,
            type: 'img'
        },
        {
            url: illustrationAllItem11,
            type: 'video'
        },
    ],
    column3: [
        {
            url: illustrationAllItem5,
            type: 'img'
        },
        {
            url: illustrationAllItem13,
            type: 'img'
        },
        {
            url: illustrationAllItem14,
            type: 'img'
        },
        {
            url: illustrationAllItem4,
            type: 'img'
        },
        {
            url: illustrationAllItem28,
            type: 'img'
        },
        {
            url: illustrationAllItem22,
            type: 'img'
        },
        {
            url: illustrationAllItem16,
            type: 'img'
        },
    ],
    column4: [
        {
            url: illustrationAllItem20,
            type: 'img'
        },
        {
            url: illustrationAllItem7,
            type: 'img'
        },
        {
            url: illustrationAllItem25,
            type: 'img'
        },
        {
            url: illustrationAllItem21,
            type: 'img'
        },
        {
            url: illustrationAllItem1,
            type: 'img'
        },
        {
            url: illustrationAllItem23,
            type: 'img'
        },
        {
            url: illustrationAllItem6,
            type: 'img'
        },
    ]
};
