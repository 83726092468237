import instagramDanissimoCover from 'assets/img/projects/instagram/danissimo/instagram-danissimo-cover.png';
import instagramDanissimoItem1 from 'assets/img/projects/instagram/danissimo/instagram-danissimo-item-1.png';
import instagramDanissimoItem2 from 'assets/img/projects/instagram/danissimo/instagram-danissimo-item-2.png';
import instagramDanissimoItem3 from 'assets/img/projects/instagram/danissimo/instagram-danissimo-item-3.jpg';
import instagramDanissimoItem4 from 'assets/img/projects/instagram/danissimo/instagram-danissimo-item-4.png';
import instagramDanissimoItem5 from 'assets/img/projects/instagram/danissimo/instagram-danissimo-item-5.png';
import instagramDanissimoItem6 from 'assets/img/projects/instagram/danissimo/instagram-danissimo-item-6.png';
import instagramDanissimoItem7 from 'assets/img/projects/instagram/danissimo/instagram-danissimo-item-7.png';
import instagramDanissimoItem9 from 'assets/img/projects/instagram/danissimo/instagram-danissimo-item-9.png';
import instagramDanissimoItem10 from 'assets/img/projects/instagram/danissimo/instagram-danissimo-item-10.png';
import instagramDanissimoItem11 from 'assets/img/projects/instagram/danissimo/instagram-danissimo-item-11.mp4';
import instagramDanissimoItem13 from 'assets/img/projects/instagram/danissimo/instagram-danissimo-item-13.mp4';
import instagramDanissimoItem14 from 'assets/img/projects/instagram/danissimo/instagram-danissimo-item-14.mp4';

export const instagramDanissimoContent = {
    name: 'Danissimo',
    link: '/category/instagram/danissimo',
    description: 'Молочный десерт от компании Danone',
    projectUrl: 'https://www.instagram.com/danissimo_belarus/',
    date: 'с 2020 по настоящее время',
    cover: instagramDanissimoCover,
    warning: true,
    ginger: true,
    column1: [
        {
            url: instagramDanissimoItem1,
            type: 'img'
        },
        {
            url: instagramDanissimoItem9,
            type: 'img'
        },
        {
            url: instagramDanissimoItem2,
            type: 'img'
        },
    ],
    column2: [
        {
            url: instagramDanissimoItem13,
            type: 'video'
        },
        {
            url: instagramDanissimoItem4,
            type: 'img'
        },
        {
            url: instagramDanissimoItem6,
            type: 'img'
        },
    ],
    column3: [
        {
            url: instagramDanissimoItem10,
            type: 'img'
        },
        {
            url: instagramDanissimoItem11,
            type: 'video'
        },
        {
            url: instagramDanissimoItem3,
            type: 'img'
        },
    ],
    column4: [
        {
            url: instagramDanissimoItem5,
            type: 'img'
        },
        {
            url: instagramDanissimoItem14,
            type: 'video'
        },
        {
            url: instagramDanissimoItem7,
            type: 'img'
        },
    ]
};
