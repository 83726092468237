import React, {useState, useMemo, useCallback} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {useLocation, useParams} from 'react-router-dom';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import {PROJECTS_BY_CATEGORY} from 'data/projects';

import scss from './description.module.scss';
import ModalWindow from "components/Project/ModalWindow";

const Description = ({screens = {}}) => {
    const [show, setShow] = useState(false);
    const [content, setContent] = useState({});
    const {isMdScreen} = screens;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const location = useLocation();
    const {category} = useParams();
    const {pathname} = location;

    const activeCategoryProjects = useMemo(() => PROJECTS_BY_CATEGORY.find(pr => `/category/${category}` === pr.link)?.projects || [], [pathname]);
    const currentProjectContent = useMemo(() => activeCategoryProjects.find(pr => pathname === pr.link) || [], [pathname]);

    const {
        name = '',
        description = '',
        projectUrl = '',
        date = '',
        ginger = false,
        level9 = false,
        bello = false,
        warning = false,
        column1 = [],
        column2 = [],
        column3 = [],
        column4 = []
    } = currentProjectContent;

    const setActiveContent = useCallback((url, type) => {
        setContent({url, type});
        handleShow();
    }, [content])

    return (
        <>
            <section className={scss.descriptionSection}>
                <Container>
                    <Row className={scss.descriptionContent}>
                        {column1?.length ? (
                            <>
                                {isMdScreen ? (
                                    <>
                                        <Col xs={12}>
                                            <div className={scss.description}>
                                                <h3>{name}</h3>
                                                <p className={scss.text}>{description}</p>
                                                {projectUrl && <p className={scss.text}>
                                                    <a href={projectUrl} target="_blank" rel="noreferrer">Увидеть вживую</a>
                                                </p>}
                                                {(ginger || level9 || bello) && (
                                                    <p>Проект создан в рамках работы с агентством
                                                        <span>
                                                            {ginger &&
                                                                <a href="https://www.instagram.com/ginger.vision.agency/"
                                                                   target="_blank" rel="noreferrer">Ginger</a>}
                                                            {level9 &&
                                                                <a href="https://www.instagram.com/level9agency/"
                                                                   target="_blank" rel="noreferrer">Level9</a>}
                                                            {bello &&
                                                                <a href="https://www.instagram.com/bello.agency/"
                                                                   target="_blank" rel="noreferrer">Bello</a>}
                                                        </span>
                                                    </p>
                                                )}
                                                {date && <p>Дата создания: {date}</p>}
                                                {warning &&
                                                    <p className={scss.warning}>Страница может загружаться дольше обычного
                                                        из-за видео, но оно того стоит :)</p>}
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                            <div className={scss.column}>
                                                {column1.map((el, index) => {
                                                    return (
                                                        <div
                                                            className={scss.item}
                                                            key={index}
                                                            onClick={() => setActiveContent(el.url, el.type)}>
                                                            {el.type === 'img' &&
                                                                <LazyLoadImage effect="blur" src={el.url} alt="Image"/>}
                                                            {el.type === 'video' &&
                                                                <video src={el.url} playsInline autoPlay loop muted/>}
                                                        </div>
                                                    )
                                                })}
                                                {column3.length ? column2.map((el, index) => {
                                                    return (
                                                        <div
                                                            className={scss.item}
                                                            key={index}
                                                            onClick={() => setActiveContent(el.url, el.type)}>
                                                            {el.type === 'img' &&
                                                                <LazyLoadImage effect="blur" src={el.url} alt="Image"/>}
                                                            {el.type === 'video' &&
                                                                <video src={el.url} playsInline autoPlay loop muted/>}
                                                        </div>
                                                    )
                                                }) : ''}
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                            <div className={scss.column}>
                                                {column3.length ? (
                                                    <>
                                                        {column3.map((el, index) => {
                                                            return (
                                                                <div
                                                                    className={scss.item}
                                                                    key={index}
                                                                    onClick={() => setActiveContent(el.url, el.type)}>
                                                                    {el.type === 'img' &&
                                                                        <LazyLoadImage effect="blur" src={el.url}
                                                                                       alt="Image"/>}
                                                                    {el.type === 'video' &&
                                                                        <video src={el.url} playsInline autoPlay loop
                                                                               muted/>}
                                                                </div>
                                                            )
                                                        })}
                                                        {column4.map((el, index) => {
                                                            return (
                                                                <div
                                                                    className={scss.item}
                                                                    key={index}
                                                                    onClick={() => setActiveContent(el.url, el.type)}>
                                                                    {el.type === 'img' &&
                                                                        <LazyLoadImage effect="blur" src={el.url}
                                                                                       alt="Image"/>}
                                                                    {el.type === 'video' &&
                                                                        <video src={el.url} playsInline autoPlay loop
                                                                               muted/>}
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                ) : (
                                                    <>
                                                        {column2.map((el, index) => {
                                                            return (
                                                                <div
                                                                    className={scss.item}
                                                                    key={index}
                                                                    onClick={() => setActiveContent(el.url, el.type)}>
                                                                    {el.type === 'img' &&
                                                                        <LazyLoadImage effect="blur" src={el.url}
                                                                                       alt="Image"/>}
                                                                    {el.type === 'video' &&
                                                                        <video src={el.url} playsInline autoPlay loop
                                                                               muted/>}
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                )}
                                            </div>
                                        </Col>
                                    </>
                                ) : (
                                    <>
                                        <Col lg={3}>
                                            <div className={scss.description}>
                                                <h3>{name}</h3>
                                                <p className={scss.text}>{description}</p>
                                                {projectUrl && <p className={scss.text}>
                                                    <a href={projectUrl} target="_blank" rel="noreferrer">Увидеть вживую</a>
                                                </p>}
                                                {(ginger || level9 || bello) && (
                                                    <p>Проект создан в рамках работы с агентством
                                                        <span>
                                                            {ginger &&
                                                                <a href="https://www.instagram.com/ginger.vision.agency/"
                                                                   target="_blank" rel="noreferrer">Ginger</a>}
                                                            {level9 &&
                                                                <a href="https://www.instagram.com/level9agency/"
                                                                   target="_blank" rel="noreferrer">Level9</a>}
                                                            {bello &&
                                                                <a href="https://www.instagram.com/bello.agency/"
                                                                   target="_blank" rel="noreferrer">Bello</a>}
                                                        </span>
                                                    </p>
                                                )}
                                                {date && <p>Дата создания: {date}</p>}
                                                {warning &&
                                                    <p className={scss.warning}>Страница может загружаться дольше обычного
                                                        из-за видео, но оно того стоит :)</p>}
                                            </div>
                                            <div className={scss.column}>
                                                {column1.map((el, index) => {
                                                    return (
                                                        <div
                                                            className={scss.item}
                                                            key={index}
                                                            onClick={() => setActiveContent(el.url, el.type)}>
                                                            {el.type === 'img' &&
                                                                <LazyLoadImage effect="blur" src={el.url} alt="Image"/>}
                                                            {el.type === 'video' &&
                                                                <video src={el.url} playsInline autoPlay loop muted/>}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className={scss.column}>
                                                {column2.map((el, index) => {
                                                    return (
                                                        <div
                                                            className={scss.item}
                                                            key={index}
                                                            onClick={() => setActiveContent(el.url, el.type)}>
                                                            {el.type === 'img' &&
                                                                <LazyLoadImage effect="blur" src={el.url} alt="Image"/>}
                                                            {el.type === 'video' &&
                                                                <video src={el.url} playsInline autoPlay loop muted/>}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className={scss.column}>
                                                {column3.map((el, index) => {
                                                    return (
                                                        <div
                                                            className={scss.item}
                                                            key={index}
                                                            onClick={() => setActiveContent(el.url, el.type)}>
                                                            {el.type === 'img' &&
                                                                <LazyLoadImage effect="blur" src={el.url} alt="Image"/>}
                                                            {el.type === 'video' &&
                                                                <video src={el.url} playsInline autoPlay loop muted/>}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className={scss.column}>
                                                {column4.map((el, index) => {
                                                    return (
                                                        <div
                                                            className={scss.item}
                                                            key={index}
                                                            onClick={() => setActiveContent(el.url, el.type)}>
                                                            {el.type === 'img' &&
                                                                <LazyLoadImage effect="blur" src={el.url} alt="Image"/>}
                                                            {el.type === 'video' &&
                                                                <video src={el.url} playsInline autoPlay loop muted/>}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </Col>
                                    </>
                                )}
                            </>
                        ) : (
                            <Col>
                                <h3>Контент для проекта пока отсутствует, зайдите позже :)</h3>
                            </Col>
                        )}
                    </Row>
                </Container>
            </section>
            <ModalWindow
                show={show}
                handleClose={handleClose}
                content={content}
            />
        </>
    )
}

export default Description;
