import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {ParallaxProvider, Parallax} from 'react-scroll-parallax';

import figure1 from 'assets/img/figures/figure-white-1.svg';
import figure2 from 'assets/img/figures/figure-white-2.svg';
import boom from 'assets/img/texts/boom-text.svg';
import pew from 'assets/img/texts/pew-text.svg';
import footerLine from "assets/img/footer-line.png";

import scss from 'components/General/Footer/footer.module.scss';

const Footer = () => (
    <ParallaxProvider>
        <footer id="contacts-section">
            <Container>
                <Row>
                    <Col className={scss.footerContent}>
                        <h2>
                            Напишите<span><img src={figure1} alt=""/></span><br/>
                            <span><img src={figure2} alt=""/></span>мне,&#8239;чтобы<br/>
                            обсудить&#8239;проект
                        </h2>
                        <div className={scss.links}>
                            <a href="https://instagram.com/teaisnotsummer" target="_blank" rel="noreferrer">
                                INSTAGRAM <span><img src={footerLine} alt="Line"/></span></a>
                            <a href="https://t.me/tsntsmmr" target="_blank" rel="noreferrer">
                                TELEGRAM <span className={scss.tg}><img src={footerLine} alt="Line"/></span></a>
                        </div>
                        <Parallax
                            rotate={[-30, 0]}
                            scale={[0.5, 1.5]}
                            className={`${scss.item} ${scss.itemLeft} parallax`}
                        >
                            <img src={pew} alt="Pew text"/>
                        </Parallax>
                        <Parallax
                            rotate={[30, 0]}
                            scale={[0.5, 1.5]}
                            className={`${scss.item} ${scss.itemRight} parallax`}
                        >
                            <img src={boom} alt="Boom text"/>
                        </Parallax>
                    </Col>
                </Row>
            </Container>
        </footer>
    </ParallaxProvider>
);

export default Footer;
