import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {ParallaxProvider, Parallax} from 'react-scroll-parallax';

import banner1 from 'assets/img/banner/home-banner-item-1.JPG';
import banner2 from 'assets/img/banner/home-banner-item-2.png';
import banner3 from 'assets/img/banner/home-banner-item-3.JPG';
import figure1 from 'assets/img/figures/figure-1.svg';
import figure2 from 'assets/img/figures/figure-2.svg';
import figure3 from 'assets/img/figures/figure-3.svg';

import scss from './banner.module.scss';

const Banner = () => (
    <ParallaxProvider>
        <section className={scss.bannerSection} id="brands-section">
            <Container fluid>
                <Row>
                    <Col className={scss.bannerContent}>
                        <div className={scss.item}>
                            <img src={banner1} alt="Banner image"/>
                        </div>
                        <div className={scss.item}>
                            <Parallax rotate={[180, 0]} className="parallax">
                                <img src={figure1} alt="Banner image"/>
                            </Parallax>
                        </div>
                        <div className={scss.item}>
                            <img src={banner2} alt="Banner image"/>
                        </div>
                        <div className={scss.item}>
                            <Parallax rotate={[0, 180]} className="parallax">
                                <img src={figure2} alt="Banner image"/>
                            </Parallax>
                        </div>
                        <div className={scss.item}>
                            <img src={banner3} alt="Banner image"/>
                        </div>
                        <div className={scss.item}>
                            <Parallax rotate={[180, 0]} className="parallax">
                                <img src={figure3} alt="Banner image"/>
                            </Parallax>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    </ParallaxProvider>
)

export default Banner;
