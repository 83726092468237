import identityPrintsCover from 'assets/img/projects/identity/prints/identity-prints-cover.png';
import identityPrintsItem1 from 'assets/img/projects/identity/prints/identity-prints-item-1.png';
import identityPrintsItem2 from 'assets/img/projects/identity/prints/identity-prints-item-2.png';
import identityPrintsItem3 from 'assets/img/projects/identity/prints/identity-prints-item-3.png';
import identityPrintsItem4 from 'assets/img/projects/identity/prints/identity-prints-item-4.png';
import identityPrintsItem5 from 'assets/img/projects/identity/prints/identity-prints-item-5.png';
import identityPrintsItem6 from 'assets/img/projects/identity/prints/identity-prints-item-6.png';
import identityPrintsItem7 from 'assets/img/projects/identity/prints/identity-prints-item-7.png';
import identityPrintsItem8 from 'assets/img/projects/identity/prints/identity-prints-item-8.mp4';
import identityPrintsItem9 from 'assets/img/projects/identity/prints/identity-prints-item-9.png';
import identityPrintsItem10 from 'assets/img/projects/identity/prints/identity-prints-item-10.png';
import identityPrintsItem11 from 'assets/img/projects/identity/prints/identity-prints-item-11.png';
import identityPrintsItem12 from 'assets/img/projects/identity/prints/identity-prints-item-12.png';
import identityPrintsItem13 from 'assets/img/projects/identity/prints/identity-prints-item-13.png';
import identityPrintsItem14 from 'assets/img/projects/identity/prints/identity-prints-item-14.png';
import identityPrintsItem15 from 'assets/img/projects/identity/prints/identity-prints-item-15.png';

export const identityPrintsContent = {
    name: 'Печатная продукция',
    link: '/category/identity/prints',
    description: 'Сборка визиток, роллапов, меню и другого',
    date: 'с 2020 по настоящее время',
    cover: identityPrintsCover,
    column1: [
        {
            url: identityPrintsItem7,
            type: 'img'
        },
        {
            url: identityPrintsItem1,
            type: 'img'
        },
        {
            url: identityPrintsItem2,
            type: 'img'
        },
    ],
    column2: [
        {
            url: identityPrintsItem4,
            type: 'img'
        },
        {
            url: identityPrintsItem9,
            type: 'img'
        },
        {
            url: identityPrintsItem6,
            type: 'img'
        },
        {
            url: identityPrintsItem14,
            type: 'img'
        },
    ],
    column3: [
        {
            url: identityPrintsItem8,
            type: 'video'
        },
        {
            url: identityPrintsItem5,
            type: 'img'
        },
        {
            url: identityPrintsItem10,
            type: 'img'
        },
    ],
    column4: [
        {
            url: identityPrintsItem12,
            type: 'img'
        },
        {
            url: identityPrintsItem15,
            type: 'img'
        },
        {
            url: identityPrintsItem13,
            type: 'img'
        },
        {
            url: identityPrintsItem11,
            type: 'img'
        },
        {
            url: identityPrintsItem3,
            type: 'img'
        },
    ]
};
