import instagramCidrCover from 'assets/img/projects/instagram/cidr/instagram-cidr-cover.png';
import instagramCidrItem1 from 'assets/img/projects/instagram/cidr/instagram-cidr-item-1.png';
import instagramCidrItem2 from 'assets/img/projects/instagram/cidr/instagram-cidr-item-2.png';
import instagramCidrItem3 from 'assets/img/projects/instagram/cidr/instagram-cidr-item-3.png';
import instagramCidrItem4 from 'assets/img/projects/instagram/cidr/instagram-cidr-item-4.png';
import instagramCidrItem5 from 'assets/img/projects/instagram/cidr/instagram-cidr-item-5.png';
import instagramCidrItem6 from 'assets/img/projects/instagram/cidr/instagram-cidr-item-6.png';
import instagramCidrItem7 from 'assets/img/projects/instagram/cidr/instagram-cidr-item-7.png';
import instagramCidrItem8 from 'assets/img/projects/instagram/cidr/instagram-cidr-item-8.png';
import instagramCidrItem9 from 'assets/img/projects/instagram/cidr/instagram-cidr-item-9.png';
import instagramCidrItem10 from 'assets/img/projects/instagram/cidr/instagram-cidr-item-10.png';
import instagramCidrItem11 from 'assets/img/projects/instagram/cidr/instagram-cidr-item-11.png';
import instagramCidrItem12 from 'assets/img/projects/instagram/cidr/instagram-cidr-item-12.png';
import instagramCidrItem13 from 'assets/img/projects/instagram/cidr/instagram-cidr-item-13.png';
import instagramCidrItem14 from 'assets/img/projects/instagram/cidr/instagram-cidr-item-14.jpg';
import instagramCidrItem15 from 'assets/img/projects/instagram/cidr/instagram-cidr-item-15.jpg';
import instagramCidrItem16 from 'assets/img/projects/instagram/cidr/instagram-cidr-item-16.jpg';

export const instagramCidrContent = {
    name: 'CIDR Family',
    link: '/category/instagram/cidr',
    description: 'Онлайн-школа графического дизайна для начинающих',
    projectUrl: 'https://www.instagram.com/cidr.design/',
    date: 'с 2020 по настоящее время',
    cover: instagramCidrCover,
    column1: [
        {
            url: instagramCidrItem7,
            type: 'img'
        },
        {
            url: instagramCidrItem13,
            type: 'img'
        },
        {
            url: instagramCidrItem3,
            type: 'img'
        },
        {
            url: instagramCidrItem4,
            type: 'img'
        },
    ],
    column2: [
        {
            url: instagramCidrItem5,
            type: 'img'
        },
        {
            url: instagramCidrItem6,
            type: 'img'
        },
        {
            url: instagramCidrItem1,
            type: 'img'
        },
        {
            url: instagramCidrItem8,
            type: 'img'
        },
    ],
    column3: [
        {
            url: instagramCidrItem16,
            type: 'img'
        },
        {
            url: instagramCidrItem9,
            type: 'img'
        },
        {
            url: instagramCidrItem10,
            type: 'img'
        },
        {
            url: instagramCidrItem11,
            type: 'img'
        },
    ],
    column4: [
        {
            url: instagramCidrItem2,
            type: 'img'
        },
        {
            url: instagramCidrItem14,
            type: 'img'
        },
        {
            url: instagramCidrItem15,
            type: 'img'
        },
        {
            url: instagramCidrItem12,
            type: 'img'
        },
    ]
};
