import React from 'react';
import {Container} from "react-bootstrap";
import {ParallaxProvider, Parallax} from 'react-scroll-parallax';

import banner from 'assets/img/home-hero-image.png';
import swipe from 'assets/img/texts/swipe-text.svg';

import scss from 'components/Home/Hero/hero.module.scss';

const Hero = ({screens = {}}) => {
    const {isMdScreen} = screens;

    return (
        <ParallaxProvider>
            <section className={scss.heroSection} id="hero-section">
                <Container>
                    <div className={scss.heroContent}>
                        <div className={scss.text}>
                            <h1>GRAPHIC<br/> DESIGNER</h1>
                            <h3>Привет! Меня зовут Лиза, я дизайнер и иллюстратор,<br/> создала десятки проектов разных
                                масштабов. Enjoy :)</h3>
                        </div>
                        <Parallax speed={isMdScreen ? -2 : 10} className="parallax">
                            <img src={swipe} alt="Swipe text"/>
                        </Parallax>
                    </div>
                </Container>
                <Container fluid>
                    <div className={scss.heroImage}>
                        <img src={banner} alt="Hero image"/>
                    </div>
                </Container>
            </section>
        </ParallaxProvider>
    )
}

export default Hero;
