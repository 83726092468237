import instagramDanoneCover from 'assets/img/projects/instagram/danone/instagram-danone-cover.png';
import instagramDanoneItem1 from 'assets/img/projects/instagram/danone/instagram-danone-item-1.jpg';
import instagramDanoneItem2 from 'assets/img/projects/instagram/danone/instagram-danone-item-2.mp4';
import instagramDanoneItem3 from 'assets/img/projects/instagram/danone/instagram-danone-item-3.mp4';
import instagramDanoneItem4 from 'assets/img/projects/instagram/danone/instagram-danone-item-4.png';
import instagramDanoneItem5 from 'assets/img/projects/instagram/danone/instagram-danone-item-5.mp4';
import instagramDanoneItem6 from 'assets/img/projects/instagram/danone/instagram-danone-item-6.mp4';
import instagramDanoneItem7 from 'assets/img/projects/instagram/danone/instagram-danone-item-7.mp4';

export const instagramDanoneContent = {
    name: 'Danone',
    link: '/category/instagram/danone',
    description: 'Молочная продукция от компании Danone',
    date: 'с 2020 по настоящее время',
    cover: instagramDanoneCover,
    warning: true,
    ginger: true,
    column1: [
        {
            url: instagramDanoneItem2,
            type: 'video'
        },
    ],
    column2: [
        {
            url: instagramDanoneItem3,
            type: 'video'
        },
        {
            url: instagramDanoneItem4,
            type: 'img'
        },
    ],
    column3: [
        {
            url: instagramDanoneItem5,
            type: 'video'
        },
        {
            url: instagramDanoneItem6,
            type: 'video'
        },
    ],
    column4: [
        {
            url: instagramDanoneItem7,
            type: 'video'
        },
        {
            url: instagramDanoneItem1,
            type: 'img'
        },
    ]
};
