import instagramKiaCover from 'assets/img/projects/instagram/kia/instagram-kia-cover.png';
import instagramKiaItem1 from 'assets/img/projects/instagram/kia/instagram-kia-item-1.png';
import instagramKiaItem2 from 'assets/img/projects/instagram/kia/instagram-kia-item-2.png';
import instagramKiaItem3 from 'assets/img/projects/instagram/kia/instagram-kia-item-3.png';
import instagramKiaItem4 from 'assets/img/projects/instagram/kia/instagram-kia-item-4.png';
import instagramKiaItem5 from 'assets/img/projects/instagram/kia/instagram-kia-item-5.png';
import instagramKiaItem6 from 'assets/img/projects/instagram/kia/instagram-kia-item-6.png';
import instagramKiaItem7 from 'assets/img/projects/instagram/kia/instagram-kia-item-7.png';
import instagramKiaItem8 from 'assets/img/projects/instagram/kia/instagram-kia-item-8.png';
import instagramKiaItem9 from 'assets/img/projects/instagram/kia/instagram-kia-item-9.png';
import instagramKiaItem10 from 'assets/img/projects/instagram/kia/instagram-kia-item-10.png';
import instagramKiaItem11 from 'assets/img/projects/instagram/kia/instagram-kia-item-11.png';

export const instagramKiaContent = {
    name: 'Kia',
    link: '/category/instagram/kia',
    description: 'Всемирно известный южнокорейский автомобильный бренд',
    date: 'с января 2021 по август 2022',
    cover: instagramKiaCover,
    ginger: true,
    column1: [
        {
            url: instagramKiaItem1,
            type: 'img'
        },
        {
            url: instagramKiaItem2,
            type: 'img'
        },
    ],
    column2: [
        {
            url: instagramKiaItem3,
            type: 'img'
        },
        {
            url: instagramKiaItem4,
            type: 'img'
        },
        {
            url: instagramKiaItem11,
            type: 'img'
        },
    ],
    column3: [
        {
            url: instagramKiaItem6,
            type: 'img'
        },
        {
            url: instagramKiaItem7,
            type: 'img'
        },

        {
            url: instagramKiaItem5,
            type: 'img'
        },
    ],
    column4: [
        {
            url: instagramKiaItem9,
            type: 'img'
        },
        {
            url: instagramKiaItem10,
            type: 'img'
        },
        {
            url: instagramKiaItem8,
            type: 'img'
        },
    ]
};
