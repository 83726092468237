import React from 'react';
import {Modal} from "react-bootstrap";

const ModalWindow = ({show, handleClose, content}) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton/>
            <Modal.Body>
                {content.type === 'img' && <img src={content.url} alt="Image"/>}
                {content.type === 'video' && <video src={content.url} playsInline autoPlay loop muted/>}
            </Modal.Body>
        </Modal>
    )
}

export default ModalWindow;
