import Home from './components/Home';
import Category from './components/Category';
import Project from './components/Project';
import NotFound from './components/NotFound';
import Links from './components/Links';
//import Oops from './components/Oops';

import './App.scss';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

const App = () => (
    <Router>
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/category/:category" component={Category}/>
            <Route exact path="/category/:category/:project" component={Project}/>
            <Route exact path="/links" component={Links}/>
            <Route component={NotFound}/>
        </Switch>
    </Router>
);

export default App;
