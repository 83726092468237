import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ParallaxProvider, Parallax} from 'react-scroll-parallax';

import arrow from "assets/img/arrow-top-right-icon.svg";
import cover from "assets/img/home-portfolio-image.PNG";
import me from "assets/img/texts/me-text.svg";

import scss from './links.module.scss';

const Links = () => {
    const LINKS = [
        {
            name: 'Мой сайт',
            link: 'https://teaisnotsummer.com',
        },
        {
            name: '«Your visual support»',
            link: 'https://t.me/yourvisualsupport',
        },
        {
            name: 'Мой Pinterest',
            link: 'https://ru.pinterest.com/teaisnotsummer',
        },
    ];

    return (
        <main>
            <ParallaxProvider>
                <section className={scss.linksSection}>
                    <Container>
                        <Row>
                            <Col md={6} className={scss.linksContent}>
                                <div className={scss.text}>
                                    <h3>Привет!</h3>
                                    <p>Я digital дизайнер и тут собраны ссылки на мой сайт, телеграм-канал и Pinterest
                                        ⚡️</p>
                                </div>
                                <div className={scss.items}>
                                    {
                                        LINKS.map((el, index) => {
                                            return (
                                                <a href={el.link} key={index} className={scss.item}>
                                                    <span>{el.name}</span>
                                                    <img src={arrow} alt="Arrow icon"/>
                                                </a>
                                            )
                                        })
                                    }
                                </div>
                            </Col>
                            <Col md={6} className={scss.linksImage}>
                                <img src={cover} alt="Portfolio image"/>
                                <Parallax speed={5} scale={[0.5, 1.5]} className="parallax">
                                    <img src={me} alt="It's me text"/>
                                </Parallax>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </ParallaxProvider>
        </main>
    )
}

export default Links;
