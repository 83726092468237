import {instagramVansContent} from 'data/projects/instagram/vans';
import {instagramCidrContent} from 'data/projects/instagram/cidr';
import {instagramCreamlyContent} from "data/projects/instagram/creamly";
import {instagramBazarContent} from 'data/projects/instagram/bazar';
import {instagramHpContent} from "data/projects/instagram/hp";
import {instagramLSSContent} from "data/projects/instagram/lss";
import {instagramDanissimoContent} from "data/projects/instagram/danissimo";
import {instagramGloContent} from "data/projects/instagram/glo";
import {instagramKiaContent} from "data/projects/instagram/kia";
import {instagramDanoneContent} from "data/projects/instagram/danone";
import {instagramGrizzlyContent} from "data/projects/instagram/grizzly";

import coverInstagram from "assets/img/covers/cover-instagram.PNG";
import coverIdentity from "assets/img/covers/cover-identity.PNG";
import coverIllustration from "assets/img/covers/cover-illustration.PNG";
// import coverWeb from "assets/img/covers/cover-web.PNG";
// import coverOther from "assets/img/covers/cover-other.PNG";
import {instagramPandaContent} from "data/projects/instagram/panda";
import {instagramVolvoContent} from "data/projects/instagram/volvo";
import {instagramVWContent} from "data/projects/instagram/vw";
import {instagramWafflesContent} from "data/projects/instagram/waffles";
import {instagramZiajaContent} from "data/projects/instagram/ziaja";
import {identityLogosContent} from "data/projects/identity/logos";
import {identityPrintsContent} from "data/projects/identity/prints";
import {identityPacksContent} from "data/projects/identity/packs";
import {identityOtherContent} from "data/projects/identity/other";
import {illustrationAllContent} from "data/projects/illustration/all";

export const PROJECTS_BY_CATEGORY = [
    {
        name: 'Instagram',
        link: '/category/instagram',
        cover: coverInstagram,
        about: `Тут собраны некоторые мои проекты для брендов в Instagram. Пишите мне, если вы хотите поработать 
        над оформлением вашего аккаунта.`,
        projects: [
            instagramVansContent,
            instagramHpContent,
            instagramCidrContent,
            instagramCreamlyContent,
            instagramPandaContent,
            instagramVWContent,
            instagramLSSContent,
            instagramGloContent,
            instagramDanissimoContent,
            instagramBazarContent,
            instagramWafflesContent,
            instagramKiaContent,
            instagramVolvoContent,
            instagramDanoneContent,
            instagramGrizzlyContent,
            instagramZiajaContent,
        ]
    },
    {
        name: 'Айдентика',
        link: '/category/identity',
        cover: coverIdentity,
        about: `Здесь можно увидеть мои кейсы по разработке логотипов, упаковок и оформлений мероприятий. Пишите мне, 
        если вам нужно что-то из перечисленного.`,
        projects: [
            identityLogosContent,
            identityPrintsContent,
            identityPacksContent,
            identityOtherContent
        ]
    },
    {
        name: 'Иллюстрации',
        link: '/category/illustration',
        cover: coverIllustration,
        about: `В этом разделе часть меня: я стараюсь вложить в каждую иллюстрацию частичку души (не как 
        Волан-де-Морт 😂). Буду очень рада поработать над вашими иллюстрациями!`,
        projects: [
            illustrationAllContent
        ]
    },
    // {
    //     name: 'Web-дизайн',
    //     link: '/category/web',
    //     cover: coverWeb,
    //     about: `Сайты и приложения — продающие инструменты, поэтому тут все предельно серьезно. Кроме создания
    //     дизайна возможна и разработка вашего сайта или лендинга.`,
    //     projects: []
    // },
    // {
    //     name: 'Прочее',
    //     link: '/category/other',
    //     cover: coverOther,
    //     about: `Сюда попало все то, что не подошло ни под одну из категорий выше или оказалось недостаточно большим для
    //      отдельного проекта. Enjoy!`,
    //     projects: []
    // },
];
