import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {Link} from 'react-router-dom';

import arrow from 'assets/img/arrow-top-right-icon.svg';

import {PRICES_ITEMS} from 'data/prices';

import scss from './prices.module.scss';

const Prices = () => (
    <section className={scss.pricesSection} id="prices-section">
        <Container>
            <Row>
                <Col className={scss.pricesContent}>
                    <div className={scss.text}>
                        <h3>ПРАЙС-ЛИСТ</h3>
                        <p>Здесь указана минимальная стоимость, точная рассчитывается индивидуально и зависит от
                            уровня сложности проекта.</p>
                    </div>
                    <div className={scss.items}>
                        {
                            PRICES_ITEMS.map((el, index) => {
                                return <Link to={el.link} key={index} className={scss.item}>
                                    <div className={scss.itemImage}>
                                        <img src={el.img} alt="Cover"/>
                                    </div>
                                    <div className={scss.itemContent}>
                                        <div className={scss.title}>
                                            {el.name}
                                        </div>
                                        <div className={scss.prices}>
                                            {el.prices.map((pr, i) => {
                                                return <div className={scss.price} key={i}>
                                                    <p>{pr.title} <span>{pr.price}</span></p>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                    <div className={scss.itemArrow}>
                                        <img src={arrow} alt="Arrow icon"/>
                                    </div>
                                </Link>
                            })
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
)

export default Prices;
