import instagramPandaCover from 'assets/img/projects/instagram/panda/instagram-panda-cover.png';
import instagramPandaItem1 from 'assets/img/projects/instagram/panda/instagram-panda-item-1.png';
import instagramPandaItem2 from 'assets/img/projects/instagram/panda/instagram-panda-item-2.png';
import instagramPandaItem3 from 'assets/img/projects/instagram/panda/instagram-panda-item-3.png';
import instagramPandaItem4 from 'assets/img/projects/instagram/panda/instagram-panda-item-4.jpg';
import instagramPandaItem5 from 'assets/img/projects/instagram/panda/instagram-panda-item-5.jpg';
import instagramPandaItem6 from 'assets/img/projects/instagram/panda/instagram-panda-item-6.jpg';
import instagramPandaItem7 from 'assets/img/projects/instagram/panda/instagram-panda-item-7.jpg';

export const instagramPandaContent = {
    name: 'Panda School',
    link: '/category/instagram/panda',
    description: 'Школа английского языка в Минске',
    projectUrl: 'https://www.instagram.com/pandaschool.by/',
    date: 'с июля 2021 по настоящее время',
    cover: instagramPandaCover,
    bello: true,
    column1: [
        {
            url: instagramPandaItem1,
            type: 'img'
        },
    ],
    column2: [
        {
            url: instagramPandaItem2,
            type: 'img'
        },
        {
            url: instagramPandaItem5,
            type: 'img'
        },
    ],
    column3: [
        {
            url: instagramPandaItem4,
            type: 'img'
        },
        {
            url: instagramPandaItem3,
            type: 'img'
        },
    ],
    column4: [
        {
            url: instagramPandaItem6,
            type: 'img'
        },
        {
            url: instagramPandaItem7,
            type: 'img'
        },
    ]
};
