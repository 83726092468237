import React, {useMemo} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {useLocation, Link} from 'react-router-dom';

import {PROJECTS_BY_CATEGORY} from 'data/projects';

import arrow from 'assets/img/arrow-top-right-icon.svg';

import scss from './hero.module.scss';

const Hero = () => {
    const location = useLocation();
    const {pathname} = location;

    const activeCategory = useMemo(() => PROJECTS_BY_CATEGORY.find(pr => pathname === pr.link), [pathname]);

    return (
        <section className={scss.heroSection}>
            <Container>
                <Row>
                    <Col lg={6} className={scss.heroContent}>
                        <div className={scss.items}>
                            {
                                PROJECTS_BY_CATEGORY.map((el, index) => {
                                    return <div
                                        className={`${scss.item} ${pathname === el.link && scss.active}`}
                                        key={index}
                                    >
                                        <Link
                                            to={el.link}
                                        >
                                            {el.name}
                                            {pathname !== el.link && <img src={arrow} alt="Arrow icon"/>}
                                        </Link>
                                    </div>
                                })
                            }
                        </div>
                        <div className={scss.text}>
                            <p>{activeCategory?.about}</p>
                        </div>
                    </Col>
                    <Col lg={6} className={scss.heroImage}>
                        <img src={activeCategory?.cover} alt="Projects cover"/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Hero;
