import identityLogosCover from 'assets/img/projects/identity/logos/identity-logos-cover.png';
import identityLogosItem1 from 'assets/img/projects/identity/logos/identity-logos-item-1.png';
import identityLogosItem2 from 'assets/img/projects/identity/logos/identity-logos-item-2.png';
import identityLogosItem3 from 'assets/img/projects/identity/logos/identity-logos-item-3.png';
import identityLogosItem4 from 'assets/img/projects/identity/logos/identity-logos-item-4.png';
import identityLogosItem5 from 'assets/img/projects/identity/logos/identity-logos-item-5.png';
import identityLogosItem6 from 'assets/img/projects/identity/logos/identity-logos-item-6.png';
import identityLogosItem7 from 'assets/img/projects/identity/logos/identity-logos-item-7.png';
import identityLogosItem8 from 'assets/img/projects/identity/logos/identity-logos-item-8.png';
import identityLogosItem9 from 'assets/img/projects/identity/logos/identity-logos-item-9.png';
import identityLogosItem10 from 'assets/img/projects/identity/logos/identity-logos-item-10.png';
import identityLogosItem11 from 'assets/img/projects/identity/logos/identity-logos-item-11.png';
import identityLogosItem12 from 'assets/img/projects/identity/logos/identity-logos-item-12.jpg';

export const identityLogosContent = {
    name: 'Логотипы',
    link: '/category/identity/logos',
    description: 'Сборка разных логотипов',
    date: 'с 2020 по настоящее время',
    cover: identityLogosCover,
    column1: [
        {
            url: identityLogosItem6,
            type: 'img'
        },
        {
            url: identityLogosItem2,
            type: 'img'
        },
        {
            url: identityLogosItem12,
            type: 'img'
        },
    ],
    column2: [
        {
            url: identityLogosItem3,
            type: 'img'
        },
        {
            url: identityLogosItem9,
            type: 'img'
        },
        {
            url: identityLogosItem7,
            type: 'img'
        },
    ],
    column3: [
        {
            url: identityLogosItem5,
            type: 'img'
        },
        {
            url: identityLogosItem1,
            type: 'img'
        },
        {
            url: identityLogosItem11,
            type: 'img'
        },
    ],
    column4: [
        {
            url: identityLogosItem10,
            type: 'img'
        },
        {
            url: identityLogosItem8,
            type: 'img'
        },
        {
            url: identityLogosItem4,
            type: 'img'
        },
    ]
};
