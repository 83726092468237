import instagramHpCover from 'assets/img/projects/instagram/hp/instagram-hp-cover.png';
import instagramHpItem1 from 'assets/img/projects/instagram/hp/instagram-hp-item-1.png';
import instagramHpItem2 from 'assets/img/projects/instagram/hp/instagram-hp-item-2.png';
import instagramHpItem3 from 'assets/img/projects/instagram/hp/instagram-hp-item-3.png';
import instagramHpItem4 from 'assets/img/projects/instagram/hp/instagram-hp-item-4.png';
import instagramHpItem5 from 'assets/img/projects/instagram/hp/instagram-hp-item-5.png';
import instagramHpItem6 from 'assets/img/projects/instagram/hp/instagram-hp-item-6.png';
import instagramHpItem7 from 'assets/img/projects/instagram/hp/instagram-hp-item-7.png';
import instagramHpItem8 from 'assets/img/projects/instagram/hp/instagram-hp-item-8.png';
import instagramHpItem9 from 'assets/img/projects/instagram/hp/instagram-hp-item-9.png';
import instagramHpItem10 from 'assets/img/projects/instagram/hp/instagram-hp-item-10.png';
import instagramHpItem11 from 'assets/img/projects/instagram/hp/instagram-hp-item-11.png';
import instagramHpItem12 from 'assets/img/projects/instagram/hp/instagram-hp-item-12.png';
import instagramHpItem13 from 'assets/img/projects/instagram/hp/instagram-hp-item-13.png';
import instagramHpItem14 from 'assets/img/projects/instagram/hp/instagram-hp-item-14.png';
import instagramHpItem15 from 'assets/img/projects/instagram/hp/instagram-hp-item-15.png';
import instagramHpItem16 from 'assets/img/projects/instagram/hp/instagram-hp-item-16.png';
import instagramHpItem17 from 'assets/img/projects/instagram/hp/instagram-hp-item-17.png';
import instagramHpItem18 from 'assets/img/projects/instagram/hp/instagram-hp-item-18.png';
import instagramHpItem19 from 'assets/img/projects/instagram/hp/instagram-hp-item-19.png';
import instagramHpItem20 from 'assets/img/projects/instagram/hp/instagram-hp-item-20.png';
import instagramHpItem21 from 'assets/img/projects/instagram/hp/instagram-hp-item-21.png';
import instagramHpItem22 from 'assets/img/projects/instagram/hp/instagram-hp-item-22.png';
import instagramHpItem23 from 'assets/img/projects/instagram/hp/instagram-hp-item-23.png';
import instagramHpItem24 from 'assets/img/projects/instagram/hp/instagram-hp-item-24.png';
import instagramHpItem25 from 'assets/img/projects/instagram/hp/instagram-hp-item-25.png';
import instagramHpItem26 from 'assets/img/projects/instagram/hp/instagram-hp-item-26.png';
import instagramHpItem27 from 'assets/img/projects/instagram/hp/instagram-hp-item-27.png';

export const instagramHpContent = {
    name: 'Hunting Pony',
    link: '/category/instagram/hp',
    description: 'Бренд товаров для домашних питомцев',
    projectUrl: 'https://www.instagram.com/huntingpony/',
    date: 'с апреля 2022 по настоящее время',
    cover: instagramHpCover,
    column1: [
        {
            url: instagramHpItem1,
            type: 'img'
        },
        {
            url: instagramHpItem2,
            type: 'img'
        },
        {
            url: instagramHpItem3,
            type: 'img'
        },
        {
            url: instagramHpItem4,
            type: 'img'
        },
        {
            url: instagramHpItem5,
            type: 'img'
        },
    ],
    column2: [
        {
            url: instagramHpItem25,
            type: 'img'
        },
        {
            url: instagramHpItem6,
            type: 'img'
        },
        {
            url: instagramHpItem7,
            type: 'img'
        },
        {
            url: instagramHpItem8,
            type: 'img'
        },
        {
            url: instagramHpItem9,
            type: 'img'
        },
        {
            url: instagramHpItem10,
            type: 'img'
        },
    ],
    column3: [
        {
            url: instagramHpItem12,
            type: 'img'
        },
        {
            url: instagramHpItem26,
            type: 'img'
        },
        {
            url: instagramHpItem13,
            type: 'img'
        },
        {
            url: instagramHpItem14,
            type: 'img'
        },
        {
            url: instagramHpItem15,
            type: 'img'
        },
        {
            url: instagramHpItem16,
            type: 'img'
        },
        {
            url: instagramHpItem17,
            type: 'img'
        },
        {
            url: instagramHpItem18,
            type: 'img'
        },
    ],
    column4: [
        {
            url: instagramHpItem27,
            type: 'img'
        },
        {
            url: instagramHpItem19,
            type: 'img'
        },
        {
            url: instagramHpItem20,
            type: 'img'
        },
        {
            url: instagramHpItem21,
            type: 'img'
        },
        {
            url: instagramHpItem22,
            type: 'img'
        },
        {
            url: instagramHpItem23,
            type: 'img'
        },
        {
            url: instagramHpItem24,
            type: 'img'
        },
        {
            url: instagramHpItem11,
            type: 'img'
        },
    ]
};
