import instagramCreamlyCover from 'assets/img/projects/instagram/creamly/instagram-creamly-cover.png';
import instagramCreamlyItem1 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-1.jpg';
import instagramCreamlyItem3 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-3.jpg';
import instagramCreamlyItem4 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-4.jpg';
import instagramCreamlyItem5 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-5.jpg';
import instagramCreamlyItem6 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-6.jpg';
import instagramCreamlyItem7 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-7.jpg';
import instagramCreamlyItem8 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-8.jpg';
import instagramCreamlyItem9 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-9.jpg';
import instagramCreamlyItem10 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-10.jpg';
import instagramCreamlyItem11 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-11.png';
import instagramCreamlyItem12 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-12.png';
import instagramCreamlyItem13 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-13.MOV';
import instagramCreamlyItem14 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-14.jpg';
import instagramCreamlyItem15 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-15.jpg';
import instagramCreamlyItem16 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-16.png';
import instagramCreamlyItem17 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-17.jpg';
import instagramCreamlyItem18 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-18.jpg';
import instagramCreamlyItem19 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-19.jpg';
import instagramCreamlyItem20 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-20.png';
import instagramCreamlyItem21 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-21.png';
import instagramCreamlyItem23 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-23.jpg';
import instagramCreamlyItem24 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-24.png';
import instagramCreamlyItem25 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-25.jpg';
import instagramCreamlyItem26 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-26.jpg';
import instagramCreamlyItem27 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-27.jpg';
import instagramCreamlyItem28 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-28.jpg';
import instagramCreamlyItem29 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-29.jpg';
import instagramCreamlyItem30 from 'assets/img/projects/instagram/creamly/instagram-creamly-item-30.jpg';

export const instagramCreamlyContent = {
    name: 'CREAM.LY',
    link: '/category/instagram/creamly',
    description: 'Бренд натуральной косметики из Нидерландов',
    date: 'с весны 2022 по настоящее время',
    cover: instagramCreamlyCover,
    column1: [
        {
            url: instagramCreamlyItem5,
            type: 'img'
        },
        {
            url: instagramCreamlyItem1,
            type: 'img'
        },
        {
            url: instagramCreamlyItem3,
            type: 'img'
        },
        {
            url: instagramCreamlyItem4,
            type: 'img'
        },
        {
            url: instagramCreamlyItem6,
            type: 'img'
        },
        {
            url: instagramCreamlyItem7,
            type: 'img'
        },
        {
            url: instagramCreamlyItem15,
            type: 'img'
        },
    ],
    column2: [
        {
            url: instagramCreamlyItem8,
            type: 'img'
        },
        {
            url: instagramCreamlyItem9,
            type: 'img'
        },
        {
            url: instagramCreamlyItem10,
            type: 'img'
        },
        {
            url: instagramCreamlyItem11,
            type: 'img'
        },
        {
            url: instagramCreamlyItem12,
            type: 'img'
        },
        {
            url: instagramCreamlyItem13,
            type: 'video'
        },
        {
            url: instagramCreamlyItem14,
            type: 'img'
        },
    ],
    column3: [
        {
            url: instagramCreamlyItem16,
            type: 'img'
        },
        {
            url: instagramCreamlyItem17,
            type: 'img'
        },
        {
            url: instagramCreamlyItem18,
            type: 'img'
        },
        {
            url: instagramCreamlyItem19,
            type: 'img'
        },
        {
            url: instagramCreamlyItem20,
            type: 'img'
        },
        {
            url: instagramCreamlyItem21,
            type: 'img'
        },
        {
            url: instagramCreamlyItem23,
            type: 'img'
        },
    ],
    column4: [
        {
            url: instagramCreamlyItem24,
            type: 'img'
        },
        {
            url: instagramCreamlyItem25,
            type: 'img'
        },
        {
            url: instagramCreamlyItem26,
            type: 'img'
        },
        {
            url: instagramCreamlyItem27,
            type: 'img'
        },
        {
            url: instagramCreamlyItem28,
            type: 'img'
        },
        {
            url: instagramCreamlyItem29,
            type: 'img'
        },
        {
            url: instagramCreamlyItem30,
            type: 'img'
        },
    ]
};
