import instagramGloCover from 'assets/img/projects/instagram/glo/instagram-glo-cover.png';
import instagramGloItem1 from 'assets/img/projects/instagram/glo/instagram-glo-item-1.png';
import instagramGloItem2 from 'assets/img/projects/instagram/glo/instagram-glo-item-2.png';
import instagramGloItem3 from 'assets/img/projects/instagram/glo/instagram-glo-item-3.png';
import instagramGloItem4 from 'assets/img/projects/instagram/glo/instagram-glo-item-4.png';
import instagramGloItem5 from 'assets/img/projects/instagram/glo/instagram-glo-item-5.png';
import instagramGloItem6 from 'assets/img/projects/instagram/glo/instagram-glo-item-6.png';
import instagramGloItem7 from 'assets/img/projects/instagram/glo/instagram-glo-item-7.png';
import instagramGloItem8 from 'assets/img/projects/instagram/glo/instagram-glo-item-8.mp4';
import instagramGloItem9 from 'assets/img/projects/instagram/glo/instagram-glo-item-9.mp4';
import instagramGloItem10 from 'assets/img/projects/instagram/glo/instagram-glo-item-10.mp4';
import instagramGloItem11 from 'assets/img/projects/instagram/glo/instagram-glo-item-11.jpg';
import instagramGloItem12 from 'assets/img/projects/instagram/glo/instagram-glo-item-12.jpg';
import instagramGloItem13 from 'assets/img/projects/instagram/glo/instagram-glo-item-13.jpg';
import instagramGloItem14 from 'assets/img/projects/instagram/glo/instagram-glo-item-14.mp4';
import instagramGloItem15 from 'assets/img/projects/instagram/glo/instagram-glo-item-15.mp4';

export const instagramGloContent = {
    name: 'glo™',
    link: '/category/instagram/glo',
    description: 'Индукционная технология нагревания glo™',
    date: 'с июня 2022 по настоящее время',
    cover: instagramGloCover,
    warning: true,
    ginger: true,
    column1: [
        {
            url: instagramGloItem15,
            type: 'video'
        },
        {
            url: instagramGloItem2,
            type: 'img'
        },
        {
            url: instagramGloItem3,
            type: 'img'
        },
    ],
    column2: [
        {
            url: instagramGloItem4,
            type: 'img'
        },
        {
            url: instagramGloItem5,
            type: 'img'
        },
        {
            url: instagramGloItem6,
            type: 'img'
        },
        {
            url: instagramGloItem7,
            type: 'img'
        },
    ],
    column3: [
        {
            url: instagramGloItem10,
            type: 'video'
        },
        {
            url: instagramGloItem9,
            type: 'video'
        },
        {
            url: instagramGloItem8,
            type: 'video'
        },
        {
            url: instagramGloItem11,
            type: 'img'
        },
    ],
    column4: [
        {
            url: instagramGloItem12,
            type: 'img'
        },
        {
            url: instagramGloItem13,
            type: 'img'
        },
        {
            url: instagramGloItem14,
            type: 'video'
        },
        {
            url: instagramGloItem1,
            type: 'img'
        },
    ]
};
