import React from 'react';
import {useMediaQuery} from "react-responsive";

import ScrollToTopOnMount from "../General/ScrollToTopOnMount";
import Header from "../General/Header";
import Hero from "./Hero";
import About from "./About";
import Portfolio from "./Portfolio";
import Doodles from "../General/Doodles";
import Brands from "./Brands";
import Banner from "./Banner";
import Prices from "./Prices";
import Footer from "../General/Footer";

const Home = () => {
    const isXsScreen = useMediaQuery({query: '(max-width: 480px)'});
    const isSmScreen = useMediaQuery({query: '(max-width: 768px)'});
    const isMdScreen = useMediaQuery({query: '(max-width: 992px)'});
    const isLgScreen = useMediaQuery({query: '(max-width: 1200px)'});

    const screens = {
        isXsScreen,
        isSmScreen,
        isMdScreen,
        isLgScreen
    }

    return (
        <>
            <ScrollToTopOnMount/>
            <Header view='home' screens={screens}/>
            <main>
                <Hero screens={screens}/>
                <About screens={screens}/>
                <Portfolio/>
                <Doodles bg='dark' screens={screens}/>
                <Brands screens={screens}/>
                <Banner/>
                <Prices/>
            </main>
            <Footer/>
        </>
    )
}

export default Home;
