import React, {useMemo} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {useLocation, Link} from 'react-router-dom';

import {PROJECTS_BY_CATEGORY} from 'data/projects';

import arrow from 'assets/img/arrow-top-right-icon.svg';

import scss from 'components/Category/Projects/projects.module.scss';

const Projects = () => {
    const location = useLocation();
    const {pathname} = location;

    const activeCategoryProjects = useMemo(() => PROJECTS_BY_CATEGORY.find(pr => pathname === pr.link)?.projects || [], [pathname]);

    return (
        <section className={scss.projectsSection}>
            <Container>
                <Row>
                    <Col className={scss.projectsContent}>
                        {activeCategoryProjects?.length ? (
                            <div className={scss.cards}>
                                {activeCategoryProjects.map((el, index) => {
                                    return <Link
                                        to={el.link}
                                        key={index}
                                        className={scss.card}
                                    >
                                        <div className={scss.cardImage}>
                                            <img src={el.cover} alt="Project cover"/>
                                        </div>
                                        <div className={scss.cardBody}>
                                            <h4>{el.name}</h4>
                                            <p>{el.description}</p>
                                            <span>Подробнее <img src={arrow} alt="Arrow icon"/></span>
                                        </div>
                                    </Link>
                                })}
                            </div>
                        ) : (
                            <div className={scss.message}>
                                <h3>Кейсы из этого раздела совсем скоро будут добавлены на сайт :)</h3>
                            </div>
                        )}
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Projects;
