import React, {useMemo} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";

import {BRANDS_LOGOS} from 'data/brands';

import scss from './brands.module.scss';
import {Link} from "react-router-dom";

const Brands = ({screens = {}}) => {
    const {isXsScreen, isSmScreen, isMdScreen} = screens;

    const slidesPerView = useMemo(() => {
        if (isXsScreen) return 2;
        if (isSmScreen) return 3;
        if (isMdScreen) return 4;
        return 5;
    }, [isXsScreen, isSmScreen, isMdScreen]);

    const spaceBetween = useMemo(() => {
        if (isXsScreen) return 40;
        if (isSmScreen) return 50;
        if (isMdScreen) return 60;
        return 80;
    }, [isXsScreen, isSmScreen, isMdScreen]);

    return (
        <section className={scss.brandsSection} id="brands-section">
            <Container>
                <Row>
                    <Col className={scss.brandsContent}>
                        <Swiper
                            navigation
                            loop={true}
                            modules={[Navigation]}
                            spaceBetween={spaceBetween}
                            slidesPerView={slidesPerView}
                            className={scss.swiper}
                        >
                            {
                                BRANDS_LOGOS.map((el, index) => {
                                    return <SwiperSlide key={index}>
                                        <Link to={el.link} className={scss.item}>
                                            <img src={el.url} alt="Brand logo"/>
                                        </Link>
                                    </SwiperSlide>
                                })
                            }
                        </Swiper>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Brands;
