import coverInstagram from "assets/img/covers/cover-instagram.PNG";
import coverIdentity from "assets/img/covers/cover-identity.PNG";
import coverIllustration from "assets/img/covers/cover-illustration.PNG";
// import coverWeb from "assets/img/covers/cover-web.PNG";
import coverOther from "assets/img/covers/cover-other.PNG";

export const PRICES_ITEMS = [
    {
        name: 'Instagram',
        link: '/category/instagram',
        img: coverInstagram,
        prices: [
            {
                title: 'Дизайны ленты',
                price: 'от 200$'
            },
            {
                title: 'Highlights',
                price: 'от 15$/штуку'
            },
            {
                title: 'Stories',
                price: 'от 20$/штуку'
            },
        ]
    },
    {
        name: 'Айдентика',
        link: '/category/identity',
        img: coverIdentity,
        prices: [
            {
                title: 'Логотип',
                price: 'от 250$'
            },
            {
                title: 'Визитка',
                price: 'от 100$'
            },
            {
                title: 'Фирменный стиль',
                price: 'от 350$'
            },
        ]
    },
    {
        name: 'Иллюстрации',
        link: '/category/illustration',
        img: coverIllustration,
        prices: [
            {
                title: 'Векторная',
                price: 'от 50$/штуку'
            },
            {
                title: 'Растровая',
                price: 'от 40$/штуку'
            },
        ]
    },
    // {
    //     name: 'Web-дизайн',
    //     link: '/category/web',
    //     img: coverWeb,
    //     prices: [
    //         {
    //             title: 'Landing page',
    //             price: 'от 400$'
    //         },
    //         {
    //             title: 'Веб-сайт',
    //             price: 'от 700$'
    //         },
    //         {
    //             title: 'Mobile app',
    //             price: 'от 30$/экран'
    //         },
    //     ]
    // },
    {
        name: 'Прочее',
        link: '/category/other',
        img: coverOther,
        prices: [
            {
                title: 'Упаковка продукта',
                price: 'от 200$'
            },
            {
                title: 'Презентация',
                price: 'от 100$'
            },
            {
                title: 'Сертификат',
                price: 'от 50$'
            },
        ]
    },
];
