import instagramGrizzlyCover from 'assets/img/projects/instagram/grizzly/instagram-grizzly-cover.png';
import instagramGrizzlyItem1 from 'assets/img/projects/instagram/grizzly/instagram-grizzly-item-1.png';
import instagramGrizzlyItem2 from 'assets/img/projects/instagram/grizzly/instagram-grizzly-item-2.png';
import instagramGrizzlyItem3 from 'assets/img/projects/instagram/grizzly/instagram-grizzly-item-3.png';
import instagramGrizzlyItem5 from 'assets/img/projects/instagram/grizzly/instagram-grizzly-item-5.png';
import instagramGrizzlyItem6 from 'assets/img/projects/instagram/grizzly/instagram-grizzly-item-6.png';
import instagramGrizzlyItem7 from 'assets/img/projects/instagram/grizzly/instagram-grizzly-item-7.png';
import instagramGrizzlyItem9 from 'assets/img/projects/instagram/grizzly/instagram-grizzly-item-9.png';

export const instagramGrizzlyContent = {
    name: 'Grizzly',
    link: '/category/instagram/grizzly',
    description: 'Владелец маркетинговой компании Grizzly',
    cover: instagramGrizzlyCover,
    level9: true,
    column1: [
        {
            url: instagramGrizzlyItem1,
            type: 'img'
        },
    ],
    column2: [
        {
            url: instagramGrizzlyItem2,
            type: 'img'
        },
        {
            url: instagramGrizzlyItem5,
            type: 'img'
        },
    ],
    column3: [
        {
            url: instagramGrizzlyItem3,
            type: 'img'
        },
        {
            url: instagramGrizzlyItem6,
            type: 'img'
        },
    ],
    column4: [
        {
            url: instagramGrizzlyItem7,
            type: 'img'
        },
        {
            url: instagramGrizzlyItem9,
            type: 'img'
        },
    ]
};
