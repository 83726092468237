import identityOtherCover from 'assets/img/projects/identity/other/identity-other-cover.png';
import identityOtherItem1 from 'assets/img/projects/identity/other/identity-other-item-1.jpg';
import identityOtherItem2 from 'assets/img/projects/identity/other/identity-other-item-2.jpeg';
import identityOtherItem3 from 'assets/img/projects/identity/other/identity-other-item-3.jpg';

export const identityOtherContent = {
    name: 'Всякое разное',
    link: '/category/identity/other',
    description: 'Остальные проекты по айдентике',
    date: 'с 2020 по настоящее время',
    cover: identityOtherCover,
    column1: [
        {
            url: identityOtherItem3,
            type: 'img'
        },
    ],
    column2: [
        {
            url: identityOtherItem2,
            type: 'img'
        },
    ],
    column3: [
        {
            url: identityOtherItem1,
            type: 'img'
        },
    ],
    column4: [

    ]
};
