import React from 'react';
import {useMediaQuery} from "react-responsive";

import ScrollToTopOnMount from "../General/ScrollToTopOnMount";
import Header from "../General/Header";
import Description from "./Description";
import Doodles from "../General/Doodles";
import Footer from "../General/Footer";

const Project = () => {
    const isXsScreen = useMediaQuery({query: '(max-width: 480px)'});
    const isSmScreen = useMediaQuery({query: '(max-width: 768px)'});
    const isMdScreen = useMediaQuery({query: '(max-width: 992px)'});
    const isLgScreen = useMediaQuery({query: '(max-width: 1200px)'});

    const screens = {
        isXsScreen,
        isSmScreen,
        isMdScreen,
        isLgScreen
    }

    return (
        <>
            <ScrollToTopOnMount />
            <Header view='goBack'/>
            <main>
                <Description screens={screens}/>
                <Doodles bg='dark' screens={screens}/>
            </main>
            <Footer/>
        </>
    )
}

export default Project;
