import instagramBazarCover from 'assets/img/projects/instagram/bazar/instagram-bazar-cover.png';
import instagramBazarItem1 from 'assets/img/projects/instagram/bazar/instagram-bazar-item-1.png';
import instagramBazarItem2 from 'assets/img/projects/instagram/bazar/instagram-bazar-item-2.png';
import instagramBazarItem3 from 'assets/img/projects/instagram/bazar/instagram-bazar-item-3.png';
import instagramBazarItem4 from 'assets/img/projects/instagram/bazar/instagram-bazar-item-4.png';
import instagramBazarItem5 from 'assets/img/projects/instagram/bazar/instagram-bazar-item-5.mp4';
import instagramBazarItem6 from 'assets/img/projects/instagram/bazar/instagram-bazar-item-6.mp4';
import instagramBazarItem7 from 'assets/img/projects/instagram/bazar/instagram-bazar-item-7.png';
import instagramBazarItem8 from 'assets/img/projects/instagram/bazar/instagram-bazar-item-8.png';
import instagramBazarItem9 from 'assets/img/projects/instagram/bazar/instagram-bazar-item-9.jpg';
import instagramBazarItem10 from 'assets/img/projects/instagram/bazar/instagram-bazar-item-10.jpg';

export const instagramBazarContent = {
    name: 'Bazar',
    link: '/category/instagram/bazar',
    description: 'Уникальный быстрый сервис доставки товаров в Минске',
    date: 'с февраля по май 2022',
    cover: instagramBazarCover,
    bello: true,
    warning: true,
    column1: [
        {
            url: instagramBazarItem7,
            type: 'img'
        },
        {
            url: instagramBazarItem1,
            type: 'img'
        },
    ],
    column2: [
        {
            url: instagramBazarItem8,
            type: 'img'
        },
        {
            url: instagramBazarItem4,
            type: 'img'
        },
        {
            url: instagramBazarItem3,
            type: 'img'
        },
    ],
    column3: [
        {
            url: instagramBazarItem5,
            type: 'video'
        },
        {
            url: instagramBazarItem9,
            type: 'img'
        },
        {
            url: instagramBazarItem2,
            type: 'img'
        },
    ],
    column4: [
        {
            url: instagramBazarItem10,
            type: 'img'
        },
        {
            url: instagramBazarItem6,
            type: 'video'
        },
    ]
};
