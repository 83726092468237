import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {ParallaxProvider, Parallax} from 'react-scroll-parallax';
import {Link} from 'react-router-dom';

import arrow from 'assets/img/arrow-top-right-icon.svg';
import cover from 'assets/img/home-portfolio-image.PNG';
import me from 'assets/img/texts/me-text.svg';

import {PROJECTS_BY_CATEGORY} from 'data/projects';

import scss from 'components/Home/Portfolio/portfolio.module.scss';

const Portfolio = () => (
    <ParallaxProvider>
        <section className={scss.portfolioSection} id="portfolio-section">
            <Container>
                <Row>
                    <Col md={6} className={scss.portfolioContent}>
                        <div className={scss.text}>
                            <h3>ПОРТФОЛИО</h3>
                            <p>Это основные направления, с которыми я работаю. Если вы не нашли среди них
                                интересующее вас — пишите мне, и мы обязательно найдем решение :)</p>
                        </div>
                        <div className={scss.items}>
                            {
                                PROJECTS_BY_CATEGORY.map((el, index) => {
                                    return (
                                        <Link to={el.link} key={index} className={scss.item}>
                                                <span>{el.name}
                                                    <span className={scss.num}>
                                                        ({el.projects.length || 0})
                                                    </span>
                                                </span>
                                            <img src={arrow} alt="Arrow icon"/>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </Col>
                    <Col md={6} className={scss.portfolioImage}>
                        <img src={cover} alt="Portfolio image"/>
                        <Parallax speed={5} scale={[0.5, 1.5]} className="parallax">
                            <img src={me} alt="It's me text"/>
                        </Parallax>
                    </Col>
                </Row>
            </Container>
        </section>
    </ParallaxProvider>
)

export default Portfolio;
