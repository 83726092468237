import {useEffect, useState} from "react";
import loader from 'assets/img/loader.svg';

import scss from './loading.module.scss';

const ScrollToTopOnMount = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => setIsLoading(false), 1000);
    }, [isLoading]);

    if (isLoading) {
        return (
            <section className={scss.loadingWrap}>
                <img src={loader} alt="Loader"/>
            </section>
        )
    }

    return null;
}

export default ScrollToTopOnMount;
