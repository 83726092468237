import instagramWafflesCover from 'assets/img/projects/instagram/waffles/instagram-waffles-cover.png';
import instagramWafflesItem1 from 'assets/img/projects/instagram/waffles/instagram-waffles-item-1.png';
import instagramWafflesItem2 from 'assets/img/projects/instagram/waffles/instagram-waffles-item-2.png';
import instagramWafflesItem3 from 'assets/img/projects/instagram/waffles/instagram-waffles-item-3.png';
import instagramWafflesItem4 from 'assets/img/projects/instagram/waffles/instagram-waffles-item-4.jpg';
import instagramWafflesItem5 from 'assets/img/projects/instagram/waffles/instagram-waffles-item-5.jpg';
import instagramWafflesItem6 from 'assets/img/projects/instagram/waffles/instagram-waffles-item-6.png';
import instagramWafflesItem7 from 'assets/img/projects/instagram/waffles/instagram-waffles-item-7.mp4';

export const instagramWafflesContent = {
    name: 'LOVE & WAFFLES',
    link: '/category/instagram/waffles',
    description: 'Пекарня с венскими вафлями в Минске',
    projectUrl: 'https://www.instagram.com/love_and_waffles/',
    date: 'с сентября 2022 по настоящее время',
    cover: instagramWafflesCover,
    level9: true,
    column1: [
        {
            url: instagramWafflesItem7,
            type: 'video'
        },
    ],
    column2: [
        {
            url: instagramWafflesItem2,
            type: 'img'
        },
        {
            url: instagramWafflesItem5,
            type: 'img'
        },
    ],
    column3: [
        {
            url: instagramWafflesItem6,
            type: 'img'
        },
        {
            url: instagramWafflesItem3,
            type: 'img'
        },
    ],
    column4: [
        {
            url: instagramWafflesItem4,
            type: 'img'
        },
        {
            url: instagramWafflesItem1,
            type: 'img'
        },
    ]
};
