import instagramVolvoCover from 'assets/img/projects/instagram/volvo/instagram-volvo-cover.png';
import instagramVolvoItem1 from 'assets/img/projects/instagram/volvo/instagram-volvo-item-1.png';
import instagramVolvoItem2 from 'assets/img/projects/instagram/volvo/instagram-volvo-item-2.png';
import instagramVolvoItem3 from 'assets/img/projects/instagram/volvo/instagram-volvo-item-3.png';
import instagramVolvoItem4 from 'assets/img/projects/instagram/volvo/instagram-volvo-item-4.png';
import instagramVolvoItem5 from 'assets/img/projects/instagram/volvo/instagram-volvo-item-5.png';
import instagramVolvoItem6 from 'assets/img/projects/instagram/volvo/instagram-volvo-item-6.png';
import instagramVolvoItem7 from 'assets/img/projects/instagram/volvo/instagram-volvo-item-7.png';

export const instagramVolvoContent = {
    name: 'Volvo',
    link: '/category/instagram/volvo',
    description: 'Всемирно известный шведский автомобильный бренд',
    projectUrl: 'https://www.instagram.com/volvoschool.by/',
    cover: instagramVolvoCover,
    level9: true,
    column1: [
        {
            url: instagramVolvoItem1,
            type: 'img'
        },
    ],
    column2: [
        {
            url: instagramVolvoItem2,
            type: 'img'
        },
        {
            url: instagramVolvoItem3,
            type: 'img'
        },
    ],
    column3: [
        {
            url: instagramVolvoItem4,
            type: 'img'
        },
        {
            url: instagramVolvoItem5,
            type: 'img'
        },
    ],
    column4: [
        {
            url: instagramVolvoItem6,
            type: 'img'
        },
        {
            url: instagramVolvoItem7,
            type: 'img'
        },
    ]
};
