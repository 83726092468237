import React, {useMemo} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {ParallaxProvider, Parallax} from 'react-scroll-parallax';

import scss from 'components/General/Doodles/doodles.module.scss';
import doodle1 from "assets/img/doodles/doodle-1.PNG";
import doodle2 from "assets/img/doodles/doodle-2.PNG";
import doodle3 from "assets/img/doodles/doodle-3.PNG";
import doodle4 from "assets/img/doodles/doodle-4.PNG";
import doodle5 from "assets/img/doodles/doodle-5.PNG";
import doodle6 from "assets/img/doodles/doodle-6.PNG";
import doodle7 from "assets/img/doodles/doodle-7.PNG";
import doodle8 from "assets/img/doodles/doodle-8.PNG";
import doodle9 from "assets/img/doodles/doodle-9.PNG";
import doodle10 from "assets/img/doodles/doodle-10.PNG";
import doodle11 from "assets/img/doodles/doodle-11.PNG";
import doodle12 from "assets/img/doodles/doodle-12.PNG";

const Doodles = ({screens = {}, bg}) => {
    const {isMdScreen} = screens;

    const bgClass = useMemo(() => {
        switch (bg) {
            case 'dark':
                return scss.darkBg
            case 'light':
                return scss.lightBg
            default:
                return scss.lightBg
        }
    }, [bg]);

    return (
        <ParallaxProvider>
            <section className={`${scss.doodlesSection} ${bgClass}`}>
                <Container>
                    <Row>
                        <Col className={scss.doodlesContent}>
                            <Parallax speed={isMdScreen ? -10 : -20} className={`${scss.item} parallax`}>
                                <img src={doodle1} alt="Doodle 1"/>
                            </Parallax>
                            <Parallax speed={isMdScreen ? 4 : 8} className={`${scss.item} parallax`}>
                                <img src={doodle2} alt="Doodle 2"/>
                            </Parallax>
                            <Parallax speed={isMdScreen ? 2 : 4} className={`${scss.item} parallax`}>
                                <img src={doodle3} alt="Doodle 3"/>
                            </Parallax>
                            <Parallax speed={isMdScreen ? -8 : -16} className={`${scss.item} parallax`}>
                                <img src={doodle4} alt="Doodle 4"/>
                            </Parallax>
                            <Parallax speed={isMdScreen ? 6 : 12} className={`${scss.item} parallax`}>
                                <img src={doodle5} alt="Doodle 5"/>
                            </Parallax>
                            <Parallax speed={isMdScreen ? 3 : 5} className={`${scss.item} parallax`}>
                                <img src={doodle6} alt="Doodle 6"/>
                            </Parallax>
                            <Parallax speed={isMdScreen ? 4 : 7} className={`${scss.item} parallax`}>
                                <img src={doodle7} alt="Doodle 7"/>
                            </Parallax>
                            <Parallax speed={isMdScreen ? -3 : -6} className={`${scss.item} parallax`}>
                                <img src={doodle8} alt="Doodle 8"/>
                            </Parallax>
                            <Parallax speed={isMdScreen ? 5 : 11} className={`${scss.item} parallax`}>
                                <img src={doodle9} alt="Doodle 9"/>
                            </Parallax>
                            <Parallax speed={isMdScreen ? -3 : -6} className={`${scss.item} parallax`}>
                                <img src={doodle10} alt="Doodle 10"/>
                            </Parallax>
                            <Parallax speed={isMdScreen ? 4 : 8} className={`${scss.item} parallax`}>
                                <img src={doodle11} alt="Doodle 11"/>
                            </Parallax>
                            <Parallax speed={isMdScreen ? -7 : -14} className={`${scss.item} parallax`}>
                                <img src={doodle12} alt="Doodle 12"/>
                            </Parallax>
                        </Col>
                    </Row>
                </Container>
            </section>
        </ParallaxProvider>
    )
}

export default Doodles;
