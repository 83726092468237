import React from 'react';
import {useMediaQuery} from "react-responsive";

import ScrollToTopOnMount from "../General/ScrollToTopOnMount";
import Header from "../General/Header";
import Hero from "./Hero";
import Projects from "./Projects";
import Doodles from "../General/Doodles";
import Footer from "../General/Footer";

const Category = () => {
    const isXsScreen = useMediaQuery({query: '(max-width: 480px)'});
    const isSmScreen = useMediaQuery({query: '(max-width: 768px)'});
    const isMdScreen = useMediaQuery({query: '(max-width: 992px)'});
    const isLgScreen = useMediaQuery({query: '(max-width: 1200px)'});

    const screens = {
        isXsScreen,
        isSmScreen,
        isMdScreen,
        isLgScreen
    }

    return (
        <>
            <ScrollToTopOnMount/>
            <Header view='goBackHome'/>
            <main>
                <Hero/>
                <Projects/>
                <Doodles bg='light' screens={screens}/>
            </main>
            <Footer/>
        </>
    )
}

export default Category;
