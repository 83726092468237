import kiaLogo from "assets/img/logos/kia-logo.svg";
import vansLogo from "assets/img/logos/vans-logo.svg";
import volkswagenLogo from "assets/img/logos/volkswagen-logo.svg";
import volvoLogo from "assets/img/logos/volvo-logo.svg";
import danissomoLogo from "assets/img/logos/danissimo-logo.svg";
import hpLogo from "assets/img/logos/hp-logo.svg";

export const BRANDS_LOGOS = [
    {
        url: kiaLogo,
        link: '/category/instagram/kia'
    },
    {
        url: vansLogo,
        link: '/category/instagram/vans'
    },
    {
        url: volkswagenLogo,
        link: '/category/instagram/vw'
    },
    {
        url: danissomoLogo,
        link: '/category/instagram/danissimo'
    },
    {
        url: volvoLogo,
        link: '/category/instagram/volvo'
    },
    {
        url: hpLogo,
        link: '/category/instagram/hp'
    },
];
