import instagramVansCover from 'assets/img/projects/instagram/vans/instagram-vans-cover.png';
import instagramVansItem1 from 'assets/img/projects/instagram/vans/instagram-vans-item-1.png';
import instagramVansItem2 from 'assets/img/projects/instagram/vans/instagram-vans-item-2.png';
import instagramVansItem3 from 'assets/img/projects/instagram/vans/instagram-vans-item-3.png';
import instagramVansItem4 from 'assets/img/projects/instagram/vans/instagram-vans-item-4.jpg';
import instagramVansItem5 from 'assets/img/projects/instagram/vans/instagram-vans-item-5.jpg';
import instagramVansItem6 from 'assets/img/projects/instagram/vans/instagram-vans-item-6.png';
import instagramVansItem7 from 'assets/img/projects/instagram/vans/instagram-vans-item-7.jpg';
import instagramVansItem8 from 'assets/img/projects/instagram/vans/instagram-vans-item-8.jpg';
import instagramVansItem9 from 'assets/img/projects/instagram/vans/instagram-vans-item-9.jpg';
import instagramVansItem10 from 'assets/img/projects/instagram/vans/instagram-vans-item-10.jpg';
import instagramVansItem11 from 'assets/img/projects/instagram/vans/instagram-vans-item-11.jpg';
import instagramVansItem12 from 'assets/img/projects/instagram/vans/instagram-vans-item-12.jpg';
import instagramVansItem13 from 'assets/img/projects/instagram/vans/instagram-vans-item-13.jpg';
import instagramVansItem14 from 'assets/img/projects/instagram/vans/instagram-vans-item-14.png';
import instagramVansItem15 from 'assets/img/projects/instagram/vans/instagram-vans-item-15.jpg';
import instagramVansItem16 from 'assets/img/projects/instagram/vans/instagram-vans-item-16.jpg';
import instagramVansItem17 from 'assets/img/projects/instagram/vans/instagram-vans-item-17.jpg';
import instagramVansItem18 from 'assets/img/projects/instagram/vans/instagram-vans-item-18.png';
import instagramVansItem19 from 'assets/img/projects/instagram/vans/instagram-vans-item-19.png';
import instagramVansItem20 from 'assets/img/projects/instagram/vans/instagram-vans-item-20.jpg';
import instagramVansItem21 from 'assets/img/projects/instagram/vans/instagram-vans-item-21.jpg';
import instagramVansItem22 from 'assets/img/projects/instagram/vans/instagram-vans-item-22.jpg';
import instagramVansItem23 from 'assets/img/projects/instagram/vans/instagram-vans-item-23.PNG';
import instagramVansItem24 from 'assets/img/projects/instagram/vans/instagram-vans-item-24.jpg';

export const instagramVansContent = {
    name: 'Vans',
    link: '/category/instagram/vans',
    description: 'Молодежный бренд обуви и предметов одежды',
    projectUrl: 'https://www.instagram.com/vans.belarus/',
    date: 'с 2020 по настоящее время',
    cover: instagramVansCover,
    ginger: true,
    column1: [
        {
            url: instagramVansItem18,
            type: 'img'
        },
        {
            url: instagramVansItem19,
            type: 'img'
        },
        {
            url: instagramVansItem2,
            type: 'img'
        },
        {
            url: instagramVansItem3,
            type: 'img'
        },
        {
            url: instagramVansItem4,
            type: 'img'
        },
    ],
    column2: [
        {
            url: instagramVansItem20,
            type: 'img'
        },
        {
            url: instagramVansItem21,
            type: 'img'
        },
        {
            url: instagramVansItem10,
            type: 'img'
        },
        {
            url: instagramVansItem6,
            type: 'img'
        },
        {
            url: instagramVansItem7,
            type: 'img'
        },
        {
            url: instagramVansItem8,
            type: 'img'
        },
    ],
    column3: [
        {
            url: instagramVansItem22,
            type: 'img'
        },
        {
            url: instagramVansItem23,
            type: 'img'
        },
        {
            url: instagramVansItem11,
            type: 'img'
        },
        {
            url: instagramVansItem5,
            type: 'img'
        },
        {
            url: instagramVansItem12,
            type: 'img'
        },
        {
            url: instagramVansItem13,
            type: 'img'
        },
    ],
    column4: [
        {
            url: instagramVansItem1,
            type: 'img'
        },
        {
            url: instagramVansItem24,
            type: 'img'
        },
        {
            url: instagramVansItem15,
            type: 'img'
        },
        {
            url: instagramVansItem16,
            type: 'img'
        },
        {
            url: instagramVansItem17,
            type: 'img'
        },
        {
            url: instagramVansItem9,
            type: 'img'
        },
    ]
};
