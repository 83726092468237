import identityPacksCover from 'assets/img/projects/identity/packs/identity-packs-cover.png';
import identityPacksItem1 from 'assets/img/projects/identity/packs/identity-packs-item-1.png';
import identityPacksItem2 from 'assets/img/projects/identity/packs/identity-packs-item-2.png';
import identityPacksItem3 from 'assets/img/projects/identity/packs/identity-packs-item-3.jpg';
import identityPacksItem4 from 'assets/img/projects/identity/packs/identity-packs-item-4.jpg';
import identityPacksItem5 from 'assets/img/projects/identity/packs/identity-packs-item-5.png';
import identityPacksItem6 from 'assets/img/projects/identity/packs/identity-packs-item-6.png';

export const identityPacksContent = {
    name: 'Упаковка',
    link: '/category/identity/packs',
    description: 'Сборка разных упаковок',
    date: 'с 2020 по настоящее время',
    cover: identityPacksCover,
    column1: [
        {
            url: identityPacksItem2,
            type: 'img'
        },
    ],
    column2: [
        {
            url: identityPacksItem5,
            type: 'img'
        },
        {
            url: identityPacksItem4,
            type: 'img'
        },
    ],
    column3: [
        {
            url: identityPacksItem1,
            type: 'img'
        },
        {
            url: identityPacksItem6,
            type: 'img'
        },
    ],
    column4: [
        {
            url: identityPacksItem3,
            type: 'img'
        },
    ]
};
