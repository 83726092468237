import React, {useRef} from 'react';
import {Navbar, Container, Nav} from "react-bootstrap";
import {Link, useHistory} from 'react-router-dom';
import {Link as ScrollLink} from "react-scroll";
import {NAVBAR_LINKS} from 'data/navbar';

import arrow from 'assets/img/arrow-top-right-icon.svg';

import scss from 'components/General/Header/header.module.scss';

const Header = ({screens = {}, view = 'goBackHome'}) => {
    const {goBack} = useHistory();
    const {isMdScreen} = screens;

    const navToggleRef = useRef(null);
    const handleNavLinkClick = () => {
        navToggleRef.current.click();
    }

    if (view === 'home') {
        return (
            <header>
                <Navbar collapseOnSelect expand="lg" className={scss.navbar}>
                    <Container>
                        <Navbar.Brand href="/">teaisnotsummer</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" ref={navToggleRef}>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                        </Navbar.Toggle>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav navbarScroll>
                                {NAVBAR_LINKS.map((el, index) => {
                                    return <ScrollLink
                                        to={el.link}
                                        duration={500}
                                        delay={300}
                                        offset={isMdScreen ? -50 : 0}
                                        key={index}
                                        onClick={isMdScreen ? handleNavLinkClick : () => {}}
                                        className="nav-link"
                                    >
                                        {el.name}
                                    </ScrollLink>
                                })}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    if (view === 'goBack') {
        return (
            <header>
                <Navbar className={`${scss.navbar} ${scss.navbarLight}`}>
                    <Container>
                        <a onClick={() => goBack()} className={scss.backLink}>
                            <img src={arrow} alt="Arrow icon"/>
                            <span>Вернуться назад</span>
                        </a>
                    </Container>
                </Navbar>
            </header>
        );
    }

    return (
        <header>
            <Navbar className={`${scss.navbar} ${scss.navbarLight}`}>
                <Container>
                    <Link to="/" className={scss.backLink}>
                        <img src={arrow} alt="Arrow icon"/>
                        <span>Вернуться на главную</span>
                    </Link>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;
